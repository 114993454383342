.spinnerDiv {
  margin-top: 5px;
}

.filename {
  color: #61dafb;
  -webkit-text-stroke: 0.25px #565656;
  text-shadow: 0px 1px 2px black;
}

.input_labels_container {
  text-align: center;
}

.input_labels {
  color: #61dafb;
  -webkit-text-stroke: 0.25px #565656;
  text-shadow: 0px 1px 2px black;
}

.input_box {
  margin-left: 8px;
  padding: 9.5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  margin-bottom: 10px;
  width: 100px;
}

.input_box_error {
  margin-left: 8px;
  padding: 9.5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  margin-bottom: 10px;
  width: 100px;
  border: 1px solid #fa8072;
}

.input_box:focus {
  outline: none;
  border: 1px solid #61dafb;
}

.loading_text {
  margin-top: 11px;
  margin-bottom: 11px;
  color: #61dafb;
  -webkit-text-stroke: 0.25px #565656;
  text-shadow: 0px 1px 2px black;
}

.video_player {
  margin-bottom: 20px;
}

.spinner {
  margin-bottom: 10px;
}
