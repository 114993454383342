.App {
  text-align: center;
  background-image: radial-gradient(circle farthest-side, #fceabb, #f8b500);
}

.App-logo {
  height: 32vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

form img,
form button {
  width: 165px;
  height: 165px;
  border: 2px solid #F0ECE8;
  border-radius: 50%;
  cursor: pointer;
  background-color: #61dafb;
  font-weight: bold;
  font-size: 18px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: #FCFBFA;
  margin: 10px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 600px) {
  form img,
  form button {
    width: 110px;
    height: 110px;
  }
}
