.welcome_header {
  color: #f9f7f6;
  -webkit-text-stroke: 0.5px #565656;
  text-shadow: 0px 1.5px 4px black;
}

.welcome_text {
  color: #fcfbfa;
  font-weight: 550;
  padding-left: 20px;
  padding-right: 20px;
}

.welcome_text_conditions {
  color: #f9f7f6;
  -webkit-text-stroke: 0.25px #565656;
  text-shadow: 0px 1px 1.75px black;
}

form button {
  margin: 10px !important;
}
